import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/utilitary.css'
import '../styles/style.css'
import { Link } from "gatsby"
import mainfoto from "../images/imagem-section12.png" // Tell webpack this JS file uses this image
import cardfoto1 from "../images/BacalhauaBraz_baixa.jpg" // Tell webpack this JS file uses this image
import cardfoto2 from "../images/BacalhauLegumes_baixa.jpg" // Tell webpack this JS file uses this image
import cardfoto3 from "../images/BacalhaucomGrao_baixa.jpg" // Tell webpack this JS file uses this image
import cardfoto4 from "../images/PapelotedeBacalhau.jpg" // Tell webpack this JS file uses this image

import scrollTo from 'gatsby-plugin-smoothscroll';


import dificult from "../images/dificuldade-vector.png" // Tell webpack this JS file uses this image
import dificult2 from "../images/diff-media.png" // Tell webpack this JS file uses this image
import dificult3 from "../images/diff-alta.png" // Tell webpack this JS file uses this image

import time from "../images/time-vector.png" // Tell webpack this JS file uses this image
import time20 from "../images/tempo-20.png" // Tell webpack this JS file uses this image
import time25 from "../images/tempo-25.png" // Tell webpack this JS file uses this image
import time40 from "../images/tempo-40.png" // Tell webpack this JS file uses this image
import time30 from "../images/tempo-30.png" // Tell webpack this JS file uses this image

import carnefly from "../images/meatshadow.png" // Tell webpack this JS file uses this image
import angus from "../images/angus-icon.png" // Tell webpack this JS file uses this image
import angusinit from "../images/angus-init.png" // Tell webpack this JS file uses this image

import logoblack from "../images/logo.png" // Tell webpack this JS file uses this image
import raca from "../images/racaanimal.png" // Tell webpack this JS file uses this image
import maturacao from "../images/maturacao.png" // Tell webpack this JS file uses this image
import sust from "../images/sustentavel.png" // Tell webpack this JS file uses this image
import logo from "../images/logo2.png" // Tell webpack this JS file uses this image
import certis from "../images/certis.png" // Tell webpack this JS file uses this image
import frescura from "../images/selo1.png" // Tell webpack this JS file uses this image
import frescura2 from "../images/selo2.png" // Tell webpack this JS file uses this image
import check from "../images/checkmsc.png" // Tell webpack this JS file uses this image

import fundo1 from "../images/fundo-1.png" // Tell webpack this JS file uses this image
import bac1 from "../images/BacalhauLegumes_baixa.jpg" // Tell webpack this JS file uses this image


import recipeicon from "../images/recipe-icon.png" // Tell webpack this JS file uses this image

import bacalhauGrelhar from "../images/bacalhau-grelhar.mp4"
import bacalhauFritar from "../images/bacalhau-fritar.mp4"
import bacalhauCozer from "../images/bacalhau-cozer.mp4"
import bacalhauAssar from "../images/bacalhau-assar.mp4"


import Helmet from "react-helmet"




const IndexPage = () => (
<Layout>


   <div id="section-1" className="white-bg">
      <div className="wrapper-container">
      <div className="right-col mobile">
            <div className="right-element" data-sal="slide-up" data-sal-delay="400"  data-sal-duration="800" data-sal-easing="ease">
               <img src={fundo1} alt="Logo" />
            </div>
         </div>
         <div className="left-col" data-sal="slide-up" data-sal-delay="400" data-sal-duration="800" data-sal-easing="ease">
            <div  className="left-element" >
               <div className="slogan ">
                  <p className="playfair-black titulo">Da Islândia <br></br> para a sua mesa</p>
                  <p className="desc sneak-regular">As mil e uma maneiras <br></br>de confecionar o nosso bacalhau.</p>
                  <button onClick={() => scrollTo('#section-2')} className="btn-sugestoes absara">Descubra as nossas sugestões</button>
                  <div className="logos">
                     <div className="cert">
                  <img  src={frescura} alt="" />
                  </div>
                  <div className="cert2">
                  <img  src={frescura2} alt="" />
                  </div>
                  <div className="angus">
                  <img  src={certis} alt="" />
                  </div>

                  </div>
               </div>
            </div>
         </div>
         <div className="right-col desktop">
            <div className="right-element" data-sal="slide-up" data-sal-delay="400"  data-sal-duration="800" data-sal-easing="ease">
               <img src={fundo1} alt="Logo" />
            </div>
         </div>
      </div>
   </div>
   <div id="section-2" className="white">
      <div className="wrapper-container">
         <div className="left-col">
         <Link to="/bacalhau-bras/">
            <div className="card-recipe" >
               <div className="wrapper">
                  <img src={cardfoto1} alt="Logo" />
                  <div className="devider">
                  </div>
                  <div className="titulo absara">
                    Bacalhau à Brás
                  </div>
                  <div className="descr sneak-regular">
                  Uma refeição rápida sem prescindir do sabor? O bacalhau à Brás é a união perfeita do prático e do tradicional.
                  </div>
                  <div className="caracts">
                     <div className="time-vector">
                        <img src={time30} alt="Logo" />
                     </div>
                     <div className="diff-vector">
                        <img src={dificult2} alt="Logo" />
                     </div>
                  </div>
               </div>
            </div>
            </Link>
         </div>
         <div className="left-center-col">
         <Link to="/papelote-bacalhau/">
            <div className="card-recipe" >
               <div className="wrapper">
                  <img src={cardfoto4} alt="Logo" />
                  <div className="devider">
                  </div>
                  <div className="titulo absara">
                    Papelote de Bacalhau com espargos
                  </div>
                  <div className="descr sneak-regular">
                  Trazemos o fiel amigo à sua mesa, num prato colorido e saudável, que promete não desapontar.
                  </div>
                  <div className="caracts">
                     <div className="time-vector">
                        <img src={time40} alt="Logo" />
                     </div>
                     <div className="diff-vector">
                        <img src={dificult2} alt="Logo" />
                     </div>
                  </div>
               </div>
            </div>
            </Link>
         </div>
         <div className="center-col">
         <Link to="/bacalhau-legumes/">
            <div className="card-recipe" >
               <div className="wrapper">
                  <img src={cardfoto2} alt="Logo" />
                  <div className="devider">
                  </div>
                  <div className="titulo absara">
                  Bacalhau com Legumes grelhados
                  </div>
                  <div className="descr sneak-regular">
                  Aliamos o bacalhau aos legumes grelhados: uma receita requintada, mas fácil e rápida.
                  </div>
                  <div className="caracts">
                     <div className="time-vector">
                        <img src={time25} alt="Logo" />
                     </div>
                     <div className="diff-vector">
                        <img src={dificult} alt="Logo" />
                     </div>
                  </div>
               </div>
            </div>
            </Link>
         </div>
         <div className="right-col">
         <Link to="/bacalhau-salada/">
            <div className="card-recipe" >
               <div className="wrapper">
                  <img src={cardfoto3} alt="Logo" />
                  <div className="devider">
                  </div>
                  <div className="titulo absara">
                     Salada de Bacalhau com grão e coentros
                  </div>
                  <div className="descr sneak-regular">
                  A salada de bacalhau com grão é um prato nutritivo, que garante todos os benefícios do bacalhau, sem descurar o sabor das suculentas lascas.
                  </div>
                  <div className="caracts">
                     <div className="time-vector">
                        <img src={time} alt="Logo" />
                     </div>
                     <div className="diff-vector">
                        <img src={dificult} alt="Logo" />
                     </div>
                  </div>
               </div>
            </div>
            </Link>
         </div>
      </div>
   </div>
   <div id="section-3" className="blue-bg">
      <div className="wrapper-container">
      </div>
   </div>


   <div id="section-videos" className="white">
      <div className="wrapper-container">
         <div className="title">
         <div className="titulo playfair-black">
            Modos de Confeção
            </div>


         </div>
      </div>

      <div className="wrapper grey-bg">
      <div className="right-col ">
                    <div className="wrapper sneak-regular">
                        <div className="conteudo">
                        <img className="img" src={recipeicon} alt="Logo" /><br></br>
                       <p className="playfair-black black">Bacalhau no Grelhador</p>
                        </div>
                        
                    </div>
                </div>
            <div className="left-col">
            <video className="video-class" preload='auto' controls autoPlay loop playsInline muted >
           <source src={bacalhauGrelhar} type="video/mp4" />
         </video>

            </div>
            
        </div>
        <div className="wrapper grey-bg">
        <div className="right-col ">
                    <div className="wrapper sneak-regular">
                        <div className="conteudo">
                        <img className="img" src={recipeicon} alt="Logo" /><br></br>
                       <p className="playfair-black black">Bacalhau Cozido ao Vapor</p>
                        </div>
                        
                    </div>
                </div>
            <div className="left-col">
            <video className="video-class" preload='auto' controls autoPlay loop playsInline muted >
           <source src={bacalhauCozer} type="video/mp4"/>
         </video>

            </div>
       
        </div>

        <div className="wrapper grey-bg">
        <div className="right-col ">
                    <div className="wrapper sneak-regular">
                        <div className="conteudo">
                        <img className="img" src={recipeicon} alt="Logo" /><br></br>
                       <p className="playfair-black black">Bacalhau na Frigideira</p>
                        </div>
                        
                    </div>
                </div>
            <div className="left-col">
            <video className="video-class" preload='auto' controls autoPlay loop playsInline muted >
           <source src={bacalhauFritar} type="video/mp4"/>
         </video>

            </div>
          
        </div>

        <div className="wrapper grey-bg">
        <div className="right-col ">
                    <div className="wrapper sneak-regular">
                        <div className="conteudo">
                        <img className="img" src={recipeicon} alt="Logo" /><br></br>
                       <p className="playfair-black black">Bacalhau no Forno</p>
                        </div>
                        
                    </div>
                </div>
            <div className="left-col">
            <video className="video-class" preload='auto' controls autoPlay loop playsInline muted >
           <source src={bacalhauAssar} type="video/mp4"/>
         </video>

            </div>
         
        </div>
   </div>



   <div id="section-4" className="">
      <div className="wrapper-container">
        <div className="left-col">
          <div className="wrapper">

          {/* <div className="titulo absara">
              Resultado de animais<br></br> da raça Angus
            </div> */}
          </div>
          

        </div>
        <div className="right-col">
        <div className="wrapper">
          <div className="titulo absara">
          <img src={logoblack} alt="Logo" />

          </div>
          <div className="desc sneak-regular">
          
          Fomos à Islândia para que lhe trazer 
o melhor bacalhau. Criado nas águas geladas, o nosso bacalhau alimenta-se principalmente de crustáceos, pequenos peixes e bivalves.
Esta alimentação, associada à baixa temperatura das águas, confere-lhe um sabor único e uma textura caracterizada por lascas longas e firmes, mas ao mesmo tempo suaves e que se separam facilmente. Deixe-se surpreender! 
Porque queremos deixar um planeta mais sustentável para as gerações vindouras, 
o nosso bacalhau é capturado de forma sustentável, sendo certificado pela distinção MSC.
O nosso bacalhau adapta-se a todos os tipos de sabores e técnicas de cozinha, seja grelhado, assado, frito, cozido 
ou ao vapor.

          </div>
          </div>
        </div>

      </div>
   </div>
   <div id="section-5">
      <div className="wrapper" data-sal="slide-up" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease">
         <div className="titulo playfair-black">
            O que significa o selo MSC?
         </div>
         <div className="imagem-msc"> <img  src={certis} alt="" /></div>

         <div className="subtitulo sneak-regular opacity ">
         Quando consome peixe com selo MSC tem a certeza que está a consumir peixe selvagem, rastreável e sustentável. <b>O que é que isso significa</b>?
         </div>
   
      </div>
      <div className="wrapper2">
         <div className="col1">
            <div className="card" data-sal="slide-up" data-sal-delay="0" data-sal-duration="300" data-sal-easing="ease">
            <img src={check} alt="checkmsc" />
            <div className="texto sneak-regular opacity">
            Significa que o peixe tem obrigatoriamente origem selvagem e não é proveniente de aquacultura.
            </div>
            </div>
         </div>
         <div className="col2">
         <div className="card" data-sal="slide-up" data-sal-delay="0" data-sal-duration="300" data-sal-easing="ease">
            <img src={check} alt="checkmsc" />
            <div className="texto sneak-regular opacity">
            Significa que sendo sustentável, assenta em três princípios fundamentais:<br></br><br></br>1.       Permanência de peixes suficientes no oceano para garantir a continuação enquanto espécie;<br></br><br></br>

2.       Baixo impacto nas restantes espécies do ecossistema;<br></br><br></br>

3.       Cumprimento de leis relevantes e capacidade de adaptação às circunstâncias ambientais.</div>
            </div>
        
            </div>
            <div className="col3">
            <div className="card" data-sal="slide-up" data-sal-delay="0" data-sal-duration="300" data-sal-easing="ease">
            <img src={check} alt="checkmsc" />
            <div className="texto sneak-regular opacity">
            Significa também que, tendo sido rastreado, há uma probabilidade inferior a 1% de atribuição deste selo por lapso.
            </div>
            </div>
            </div>
      </div>
      <div className="wrapper-3">
     

         <div className="text sneak-regular opacity">
         Consumir peixe com selo MSC é contribuir para uma transformação global em que as gerações futuras terão os seus recursos assegurados!
         </div>
      </div>
   </div>
 

</Layout>
)
export default IndexPage